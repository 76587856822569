import type { WithDateStrings } from '@dendra/utils';
import { Injectable, inject } from '@angular/core';
import {
  EntityState,
  EntityStore,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { DRFPaginatedResponse, toAkitaPaginatedResponse } from '@dendra/utils';
import { map } from 'rxjs';
import { TargetArea } from './target-area';

export type Intervention = {
  id: number;
  name: string;
  description: string;
  type:
    | 'infrastructure'
    | 'seed_collection'
    | 'seeding'
    | 'waste_removal'
    | 'weed_treatment';
  status: 'pending' | 'in_progress' | 'completed' | 'overdue';
  target_area: number;
  target_area_name: string;
  created_at: Date;
  start_date?: Date;
  due_date?: Date;
  completed_at?: Date;
  completion_metadata?: Record<string, unknown>;
  classes: number[];
};

type _ReadOnlyFields =
  | 'status'
  | 'target_area_name'
  | 'created_at'
  | 'completion_metadata';

export type InterventionToCreate = Omit<Intervention, 'id' | _ReadOnlyFields>;

export type InterventionToEdit = Omit<Intervention, _ReadOnlyFields>;

export type InterventionSummary = {
  total: number;
  pending: number;
  completed: number;
  overdue: number;
};

export type InterventionResponseResult = WithDateStrings<Intervention>;

export interface InterventionState extends EntityState<Intervention, number> {}

@StoreConfig({ name: 'intervention' })
@Injectable({ providedIn: 'root' })
export class InterventionStore extends EntityStore<InterventionState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class InterventionQuery extends QueryEntity<InterventionState> {
  protected store: InterventionStore;

  constructor() {
    const store = inject(InterventionStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class InterventionService extends NgEntityService<InterventionState> {
  protected store: InterventionStore;
  query = inject(InterventionQuery);

  static DEFAULT_PAGE_SIZE = 20;

  constructor() {
    const store = inject(InterventionStore);

    super(store);
    this.store = store;
  }

  /** Return the `n` most recent interventions and a total count for a target area, by start date */
  getRecentForTargetArea = (
    targetAreaId: TargetArea['id'],
    { count = 3 } = {},
  ) =>
    this.getHttp()
      .get<DRFPaginatedResponse<InterventionResponseResult>>(
        this.resolveUrl(),
        {
          params: {
            target_area: targetAreaId.toString(),
            page_size: count.toString(),
            ordering: '-start_date',
          },
        },
      )
      .pipe(
        map(({ results, count }) => ({
          count,
          interventions: results.map(this.hydrateInterventionResult),
        })),
      );

  getSummaryForCollection(collectionId: number) {
    return this.getHttp().get<InterventionSummary>(
      this.resolveUrl({ urlPostfix: 'summary' }),
      { params: { collection: collectionId.toString() } },
    );
  }

  getPage(
    page = 1,
    page_size = InterventionService.DEFAULT_PAGE_SIZE,
    filterParams: { name?: string; status?: string; created_at?: string } = {},
  ) {
    const params = {
      page,
      page_size,
      ...filterParams,
    };
    const mapToAkitaResponse = toAkitaPaginatedResponse(
      this.hydrateInterventionResult,
      page_size,
    );
    return this.getHttp()
      .get<
        DRFPaginatedResponse<InterventionResponseResult>
      >(this.resolveUrl(), { params })
      .pipe(mapToAkitaResponse(page));
  }

  create(intervention: InterventionToCreate) {
    return this.add<Intervention>(intervention as Intervention);
  }

  private hydrateInterventionResult = (
    intervention: InterventionResponseResult,
  ): Intervention => ({
    ...intervention,
    created_at: new Date(intervention.created_at),
    start_date: this.hydrateDate(intervention.start_date),
    due_date: this.hydrateDate(intervention.due_date),
    completed_at: this.hydrateDate(intervention.completed_at),
  });

  private hydrateDate = (dateString?: string | null) => {
    if (!dateString) {
      return null;
    }
    return new Date(dateString);
  };
}
